import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from '../axios/axios2';
import { RootState } from '../../store';
import { Notification } from '../../common/common';

interface NotificationState {
	notifications: Notification[];
	status: string;
	error?: string;
	refetch: boolean;
}

const initialState: NotificationState = {
	notifications: [],
	status: 'idle',
	error: '',
	refetch: false,
};

export const fetchFilteredNotifications = createAsyncThunk(
	'notifications/fetchFilteredNotifications',
	async (clients: String[]) => {
		const payload = {
			clients: clients,
		};
		const res = await axios.post('/notifications/by/clients', payload);
		return res.data;
	}
);

export const refetchFilteredNotifications = createAsyncThunk<
	Notification[],
	String[]
>('notifications/refetchFilteredNotifications', async (clients: String[]) => {
	const payload = {
		clients: clients,
	};
	const res = await axios.post('/notifications/by/clients', payload);
	const data: Notification[] = await res.data;
	return data;
});

export const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		update: (state, action: PayloadAction<any>) => {
			const newArray: Notification[] = [...state.notifications];
			newArray[action.payload.index] = action.payload.client;
			return {
				...state,
				clients: newArray,
			};
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchFilteredNotifications.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetchFilteredNotifications.fulfilled, (state, action) => {
				state.status = 'succeeded';
				// Add any fetched posts to the array
				state.notifications = action.payload;
			})
			.addCase(fetchFilteredNotifications.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			})
			.addCase(refetchFilteredNotifications.pending, (state, action) => {
				state.refetch = true;
			})
			.addCase(refetchFilteredNotifications.fulfilled, (state, action) => {
				//state.status = 'succeeded';
				// Add any fetched posts to the array
				state.notifications = action.payload;
				state.refetch = false;
			})
			.addCase(refetchFilteredNotifications.rejected, (state, action) => {
				//state.status = 'failed';
				state.error = action.error.message;
			});
	},
});

export const { update } = notificationsSlice.actions;

export default notificationsSlice.reducer;

export const selectAllNotifications = (state: RootState) =>
	state.notifications.notifications;
