import { Grid, Box, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store';
import Loading from '../misc/Loading';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PrintCSV from './PrintCSV';
import { fetchFilteredClients, selectAllClients } from '../slices/clientsSlice';

//! This is our parent component for the admin panel, this should contain
//* Any universal data fetching used by children
//* Any CRUD functions that children perform if they require data

export default function Admin() {
	//? Get user information as we need their email for the
	const { user, isLoading } = useAuth0();
	//? Redux dispatcher used to dispatch queries
	const dispatch = useAppDispatch();
	//? Querie to fetch data from redux store once a query has been dispatched
	const clients = useAppSelector(selectAllClients);
	//? Status for the computers store so we know when it is loading or completed
	const status = useAppSelector((state) => state.clients.status);

	//? Use effect function that triggers on initial render and whenever the specified dependecy changes, in this case status (specified at ()=> {}, [*deps*])
	useEffect(() => {
		//? If status idle then fetch computers, if status == successful that means query is already performed, could contain old data so a refresh would not hurt to include here
		if (status === 'idle' && user) {
			dispatch(fetchFilteredClients(user.email!));
		}
		if (status === 'idle') {
			//? Refetch query here
		}
	}, [status]);

	//? Show loading component while base fetches are being performed
	if (status !== 'succeeded' || isLoading) {
		return <Loading />;
	}
	return (
		<Grid container spacing={2} alignItems='center' justifyContent='center'>
			<Grid item xs={8}>
				<Box sx={{ height: 600, width: '100%' }}>
					<Typography variant='h4' align='center' mt={2} mb={2}>
						Admin
					</Typography>
					<Grid
						container
						sx={{
							border: '1px solid',
							borderColor: '#e5e9de',
							borderRadius: '4px',
							backgroundColor: '#e5e9de',
							mt: 2,
							boxShadow: 2,
						}}
					>
						<PrintCSV clients={clients} />
					</Grid>
				</Box>
			</Grid>
		</Grid>
	);
}
