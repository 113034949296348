import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from '../axios/axios2';
import { RootState } from '../../store';
import { Client, Computer, ClientInput } from '../../common/common';

interface ClientState {
	clients: Client[];
	status: string;
	error: string | undefined;
	selectedClientId: string;
	refetch: boolean;
}

const initialState: ClientState = {
	clients: [],
	status: 'idle',
	selectedClientId: '',
	error: '',
	refetch: false,
};

export const fetchClients = createAsyncThunk<Client[]>(
	'clients/fetchClients',
	async () => {
		const res = await axios.get('/clients');
		const data: Client[] = await res.data;
		return data;
	}
);

export const fetchFilteredClients = createAsyncThunk<Client[], string>(
	'clients/fetchFilteredClients',
	async (email: string) => {
		const res = await axios.get('/clients/filter/' + email);
		const data: Client[] = await res.data;
		return data;
	}
);

export const refetchFilteredClients = createAsyncThunk<Client[], string>(
	'clients/refetchFilteredClients',
	async (email: string) => {
		const res = await axios.get('/clients/filter/' + email);
		const data: Client[] = await res.data;
		return data;
	}
);

export const updateClient = createAsyncThunk(
	'clients/updateClient',
	async (updatedClient: ClientInput) => {
		const url = '/clients/' + updatedClient._id;
		const payload = Object.assign({}, updatedClient);
		delete payload['_id'];
		const res = await axios.put(url, payload);
		return res.data;
	}
);

export const clientsSlice = createSlice({
	name: 'clients',
	initialState,
	reducers: {
		update: (state, action: PayloadAction<any>) => {
			const newArray = [...state.clients];
			newArray[action.payload.index] = action.payload.client;
			return {
				...state,
				clients: newArray,
			};
		},
		setSelectedClient: (state, action: PayloadAction<any>) => {
			return {
				...state,
				selectedClientId: action.payload,
			};
		},
		updateFriendlyName: (state, action: PayloadAction<any>) => {
			const newComputer = {
				...action.payload,
			};
			const clientIndex = state.clients.findIndex(
				(c: Client) => c._id === newComputer.clientId
			);
			const computerIndex = state.clients[clientIndex].computers.findIndex(
				(c: Computer) => c._id === newComputer._id
			);
			state.clients[clientIndex].computers[computerIndex] = newComputer;
			return state;
		},
		default: (state) => {
			return state;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchClients.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetchClients.fulfilled, (state, action) => {
				state.status = 'succeeded';
				// Add any fetched posts to the array
				state.clients = action.payload;
			})
			.addCase(fetchClients.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			})
			.addCase(fetchFilteredClients.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetchFilteredClients.fulfilled, (state, action) => {
				state.status = 'succeeded';
				// Add any fetched posts to the array
				state.clients = action.payload;
				if (state.selectedClientId === '') {
					state.selectedClientId = action.payload[0]._id as string;
				}
			})
			.addCase(fetchFilteredClients.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			})
			.addCase(refetchFilteredClients.pending, (state, action) => {
				state.refetch = true;
			})
			.addCase(refetchFilteredClients.fulfilled, (state, action) => {
				//state.status = 'succeeded';
				// Add any fetched posts to the array
				state.clients = action.payload;
				state.refetch = false;
			})
			.addCase(refetchFilteredClients.rejected, (state, action) => {
				//state.status = 'failed';
				state.error = action.error.message;
			})
			.addCase(updateClient.fulfilled, (state, action) => {
				const clients = [...state.clients];
				const index = state.clients.findIndex(
					(client) => client._id === action.payload._id
				);
				clients[index] = action.payload;
				state.clients = clients;
			});
	},
});

export const { update, setSelectedClient, updateFriendlyName } =
	clientsSlice.actions;

export default clientsSlice.reducer;

export const selectAllClients = (state: RootState) => state.clients.clients;

export const selectedClientId = (state: RootState) =>
	state.clients.selectedClientId;

export const selectComputers = (state: RootState) =>
	state.clients.clients.find(
		(c: Client) => c._id === state.clients.selectedClientId
	)?.computers as Computer[];

export const selectedClient = (state: RootState) =>
	state.clients.clients.find(
		(c: Client) => c._id === state.clients.selectedClientId
	);
