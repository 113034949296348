import React from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Optionals } from '../../common/common';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

type FieldProps = {
	placeholder?: string;
	name: string;
	value: string;
	validate: (val: string) => string | boolean;
	onChange: ({
		name,
		value,
		error,
	}: {
		name: string;
		value: string;
		error: string | boolean;
	}) => void;
	label: string;
};

type FieldState = {
	value: string;
	error: string | boolean;
	show: boolean;
};

class PwdField extends React.Component<FieldProps, FieldState> {
	state: FieldState = {
		value: this.props.value,
		error: false,
		show: false,
	};

	static getDerivedStateFromProps(nextProps: FieldProps) {
		return { value: nextProps.value };
	}

	onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const name = this.props.name;
		const value = evt.target.value;
		const error = this.props.validate(value)
			? this.props.validate(value)
			: false;

		this.setState({ value, error });

		this.props.onChange({ name, value, error });
	};

	handleClickShowPassword = () => this.setState({ show: !this.state.show });

	handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	render() {
		if (this.state.error) {
			return (
				<TextField
					required
					sx={{ mt: 3 }}
					fullWidth
					error
					placeholder={this.props.placeholder}
					value={this.state.value}
					onChange={this.onChange}
					className='form-control'
					label={this.props.label}
					helperText={this.state.error}
					variant='standard'
					type={this.state.show ? 'text' : 'password'}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								<IconButton
									aria-label='toggle password visibility'
									onClick={this.handleClickShowPassword}
									onMouseDown={this.handleMouseDownPassword}
									edge='end'
								>
									{this.state.show ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			);
		} else {
			return (
				<TextField
					required
					sx={{ mt: 3 }}
					fullWidth
					placeholder={this.props.placeholder}
					value={this.state.value}
					onChange={this.onChange}
					className='form-control'
					label={this.props.label}
					variant='standard'
					type={this.state.show ? 'text' : 'password'}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								<IconButton
									aria-label='toggle password visibility'
									onClick={this.handleClickShowPassword}
									onMouseDown={this.handleMouseDownPassword}
									edge='end'
								>
									{this.state.show ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			);
		}
	}
}

export default PwdField;
