import Navbar from './components/misc/Navbar';
import Devices from './components/devices/Devices';
import Management from './components/management/Managment';
import Logout from './components/login/Logout';
import Deployment from './components/deploy/Deployment';
import Report from './components/report/Report';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import { useAuth0 } from '@auth0/auth0-react';
import store from './store';
import { Provider } from 'react-redux';
import Loading from './components/misc/Loading';
import Admin from './components/admin/Admin';

function App(): JSX.Element {
	const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
	if (isLoading) {
		return <Loading />;
	}
	if (!isAuthenticated) {
		loginWithRedirect();
	}

	return (
		<Provider store={store}>
			<div>
				<BrowserRouter>
					<header>
						<Navbar />
					</header>
					<Routes>
						<Route path='/devices' element={<Devices />} />
						<Route path='/logout' element={<Logout />} />
						<Route path='/management' element={<Management />} />
						<Route path='/deployment' element={<Deployment />} />
						<Route path='/report' element={<Report />} />
						<Route path='/admin' element={<Admin />} />
						<Route path='*' element={<Report />} />
					</Routes>
				</BrowserRouter>
			</div>
		</Provider>
	);
}

export default App;
