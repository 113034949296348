import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import clientsReducer from './components/slices/clientsSlice';
import computerReducer from './components/slices/computerSlice';
import usersReducer from './components/slices/userSlice';
import notificationsReducer from './components/slices/notificationSlice';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

//onst thunk = applyMiddleware(thunkMiddleware);

const store = configureStore({
	reducer: {
		clients: clientsReducer,
		computers: computerReducer,
		users: usersReducer,
		notifications: notificationsReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(thunkMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
