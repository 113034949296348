import React from 'react';
import DeviceList from './DeviceList';
import { Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../misc/Loading';

const Device = () => {
	const { user, isLoading } = useAuth0();
	if (isLoading) {
		return <Loading />;
	}
	return (
		<Container maxWidth='xl'>
			<Typography variant='h4' align='center' mt={6} mb={2}></Typography>
			{}
			<DeviceList user={user?.email as string} />
		</Container>
	);
};

export default Device;
