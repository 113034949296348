import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

const Loading = () => {
	return (
		<Grid
			container
			spacing={0}
			direction='column'
			alignItems='center'
			justifyContent='center'
			style={{ minHeight: '50vh' }}
		>
			<Grid item xs={3}>
				<CircularProgress />
			</Grid>
		</Grid>
	);
};

export default Loading;
