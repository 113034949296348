import { Box } from '@mui/material';
import {
	DataGrid,
	GridColDef,
	GridEventListener,
	GridCellParams,
	MuiEvent,
} from '@mui/x-data-grid';
import { useState } from 'react';
import { connect } from 'react-redux';
import { AuthUser, Optionals } from '../../common/common';
import { RootState } from '../../store';
import UserToolBar from './UserToolBar';
import { selectAllClients, updateClient } from '../slices/clientsSlice';
import { useAppDispatch } from '../../store';
import UserCard from './UserCard';
import axios from '../axios/axios2';

interface UserSelectProps {
	users: string[];
	clientId: string;
	onChange: ({
		name,
		value,
		error,
	}: {
		name: string;
		value: string | Optionals | string[];
		error: string | boolean;
	}) => void;
	allUsers: [];
}

declare module '@mui/x-data-grid' {
	interface ToolbarPropsOverrides {
		onChangeHandler: (newUser: string) => void;
	}
}

const UserSelect = (props: UserSelectProps) => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [selectedUser, setSelectedUser] = useState();
	const dispatch = useAppDispatch();
	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'Name',
			flex: 1,
			editable: false,
		},
		{
			field: 'email',
			headerName: 'Email',
			flex: 1,
			editable: false,
		},
		{
			field: 'emails',
			headerName: 'Permissions',
			flex: 0.5,
			editable: false,
			valueGetter: (params) => {
				if (params.row.app_metadata.permission === 'admin') {
					return 'Admin';
				} else {
					return 'User';
				}
			},
		},
	];
	const handleRowClick: GridEventListener<'rowClick'> = (params) => {
		setSelectedUser(
			props.allUsers.find((u: AuthUser) => params.row.user_id === u.user_id)
		);
		handleOpen();
	};

	const deleteUser = () => {
		if (selectedUser) {
			const newUsers = users.filter(
				(u: string) => (selectedUser as AuthUser).email !== u
			);
			setUsers(newUsers);
			props.onChange({
				name: 'users',
				value: newUsers,
				error: false,
			});
			dispatch(
				updateClient({
					_id: props.clientId,
					users: newUsers,
				})
			);
			axios
				.delete('/users/' + (selectedUser as AuthUser).user_id)
				.then((res) => {});
			handleClose();
		}
	};

	const [users, setUsers] = useState(props.users);

	const onChangeHandler = (newUser: string) => {
		const newUsers = [...users];
		if (!users.includes(newUser)) {
			newUsers.push(newUser);
		}
		setUsers(newUsers);
		props.onChange({
			name: 'users',
			value: newUsers,
			error: false,
		});
		dispatch(
			updateClient({
				_id: props.clientId,
				users: newUsers,
			})
		);
	};

	return (
		<div>
			{selectedUser && (
				<UserCard
					open={open}
					handleClose={handleClose}
					user={selectedUser}
					deleteUser={deleteUser}
				/>
			)}
			<Box
				sx={{
					width: '100%',
					minHeight: 450,
					maxHeight: 450,
				}}
			>
				<DataGrid
					getRowId={(row) => row.user_id}
					rows={props.allUsers.filter((u: AuthUser) =>
						props.users.includes(u.email)
					)}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 10,
							},
						},
					}}
					onRowClick={handleRowClick}
					pageSizeOptions={[10]}
					sx={{
						background: '#d8dbd1',
						borderColor: '#d8dbd1',
						boxShadow: 2,
						'& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
							borderColor: '#CDD1C6',
						},
						'& .MuiDataGrid-columnHeaders': {
							borderColor: '#CDD1C6',
						},
						'& .MuiDataGrid-footerContainer': {
							borderColor: '#d8dbd1',
						},
						'&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
							outline: 'none !important',
						},
						height: 503,
					}}
					slots={{
						toolbar: UserToolBar,
					}}
					slotProps={{
						toolbar: {
							onChangeHandler: onChangeHandler,
						},
					}}
				/>
			</Box>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { users, clients } = state;
	return { allUsers: users.users, clientId: clients.selectedClientId };
}

export default connect(mapStateToProps)(UserSelect);
