import { Fragment } from 'react';
import { Computer } from '../../common/common';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

interface Props {
	computers: Computer[];
}

const Device = ({ computer }: { computer: Computer }) => {
	return (
		<Fragment>
			<Grid container direction='row' alignItems='center'>
				<Grid item xs={4}>
					<Box alignItems='center'>
						<Typography variant='h6' component='p'>
							{computer.hostName}
						</Typography>
						<Typography variant='subtitle2'>{computer.friendlyName}</Typography>
					</Box>
				</Grid>
				<Grid item xs={3}>
					<Typography>{computer.lastOnline}</Typography>
				</Grid>
				<Grid item xs={3}>
					<Typography>{computer.complianceScore}</Typography>
				</Grid>
				<Grid item xs={2}>
					<Stack>
						<Typography>
							{computer.complianceScore &&
							parseInt(
								computer.complianceScore.substring(
									0,
									computer.complianceScore.indexOf('/')
								)
							) > 320 ? (
								<CheckBoxIcon color='primary' />
							) : (
								<IndeterminateCheckBoxIcon color='error' />
							)}
						</Typography>
					</Stack>
				</Grid>
			</Grid>
			<Divider />
		</Fragment>
	);
};

const RptDevice = ({ computers }: Props) => {
	return (
		<Grid item xs={12}>
			<Box
				sx={{
					border: '1px solid',
					borderColor: '#e5e9de',
					borderRadius: '4px',
					backgroundColor: '#e5e9de',
					boxShadow: 2,
					p: 1,
				}}
			>
				<Grid container direction='row' alignItems='center' mt={1} mb={1}>
					<Grid item xs={4}>
						<Box alignItems='center'>
							<Typography variant='h6'>Device Name</Typography>
						</Box>
					</Grid>
					<Grid item xs={3}>
						<Typography variant='h6'>Scan Date</Typography>
					</Grid>
					<Grid item xs={3}>
						<Typography variant='h6'>Score</Typography>
					</Grid>
					<Grid item xs={2}>
						<Typography variant='h6'>Compliant</Typography>
					</Grid>
				</Grid>
				<Divider />
				{computers && computers.map((c) => <Device computer={c} />)}
				<Divider />
			</Box>
		</Grid>
	);
};

export default RptDevice;
