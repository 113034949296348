import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import { Grid, FormControlLabel, FormGroup } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';
import { Optionals } from '../../common/common';

type Props = {
	computerId: string;
	optionals: Optionals;
	updateOptionals: (optionals: Optionals, computerId: string) => void;
};

const Options = (props: Props) => {
	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
		null
	);
	const [optionals, setOptionals] = useState(props.optionals);

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newOptions: Optionals = Object.assign({}, optionals);

		newOptions[e.target.name] = newOptions[e.target.name] === 1 ? 0 : 1;

		setOptionals(newOptions);
	};

	const onClickHandler = (e: React.FormEvent) => {
		e.preventDefault();
		handleCloseUserMenu();
		props.updateOptionals(optionals, props.computerId);
	};

	return (
		<Box sx={{ flexGrow: 0 }}>
			<Tooltip title='Open options'>
				<Button
					variant='contained'
					onClick={handleOpenUserMenu}
					endIcon={<KeyboardArrowDownIcon />}
				>
					Customize
				</Button>
			</Tooltip>
			<Menu
				sx={{ mt: '45px' }}
				id='menu-appbar'
				anchorEl={anchorElUser}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={Boolean(anchorElUser)}
				onClose={handleCloseUserMenu}
			>
				<Grid
					sx={{
						pl: 2,
						mb: 2,
					}}
				>
					<Typography mt={1} variant='h6'>
						Options
					</Typography>
				</Grid>
				<form onSubmit={onClickHandler}>
					<Grid
						container
						m={2}
						sx={{
							width: '700px',
							borderBottom: 1,
							borderTop: 1,
							pb: 2,
							pt: 2,
							borderColor: 'grey.300',
						}}
					>
						<Grid item xs={4}>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											inputProps={{ name: 'OneDrive' }}
											checked={optionals['OneDrive'] === 1}
											onChange={onChangeHandler}
										/>
									}
									label='OneDrive'
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={optionals['Microsoft_Store'] === 1}
											inputProps={{
												name: 'Microsoft_Store',
											}}
											onChange={onChangeHandler}
										/>
									}
									label='Microsoft Store'
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={optionals['Windows_Credential_Manager'] === 1}
											inputProps={{
												name: 'Windows_Credential_Manager',
											}}
											onChange={onChangeHandler}
										/>
									}
									label='Windows Credential Manager'
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={optionals['Windows_Mobile_Hotspot'] === 1}
											inputProps={{
												name: 'Windows_Mobile_Hotspot',
											}}
											onChange={onChangeHandler}
										/>
									}
									label='Windows Mobile Hotspot'
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={4}>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={optionals['Picture_Password_Login'] === 1}
											inputProps={{
												name: 'Picture_Password_Login',
											}}
											onChange={onChangeHandler}
										/>
									}
									label='Picture Password Login'
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={optionals['Windows_Password_Manager'] === 1}
											inputProps={{
												name: 'Windows_Password_Manager',
											}}
											onChange={onChangeHandler}
										/>
									}
									label='Windows Password Manager'
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={optionals['Cortana'] === 1}
											inputProps={{
												name: 'Cortana',
											}}
											onChange={onChangeHandler}
										/>
									}
									label='Cortana'
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={optionals['Online_Speech_Recognition'] === 1}
											inputProps={{
												name: 'Online_Speech_Recognition',
											}}
											onChange={onChangeHandler}
										/>
									}
									label='Online Speech Recognition'
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={4}>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={
												optionals[
													'Allow_Standard_User_to_Elevate_Privileges'
												] === 1
											}
											inputProps={{
												name: 'Allow_Standard_User_to_Elevate_Privileges',
											}}
											onChange={onChangeHandler}
										/>
									}
									label='Allow Standard User to Elevate Privileges'
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={optionals['Xbox_Gaming'] === 1}
											inputProps={{
												name: 'Xbox_Gaming',
											}}
											onChange={onChangeHandler}
										/>
									}
									label='Xbox Gaming'
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={optionals['Format_USB_Removable_Media'] === 1}
											inputProps={{
												name: 'Format_USB_Removable_Media',
											}}
											onChange={onChangeHandler}
										/>
									}
									label='Format USB Removable Media'
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={
												optionals['Connection_to_Windows_Home_Group'] === 1
											}
											inputProps={{
												name: 'Connection_to_Windows_Home_Group',
											}}
											onChange={onChangeHandler}
										/>
									}
									label='Connection to Windows Home Group'
								/>
							</FormGroup>
						</Grid>
					</Grid>
					<Grid
						container
						justifyContent='flex-end'
						sx={{
							pl: 1,
							pr: 4,
							pb: 1,
						}}
					>
						<Button type='submit' variant='contained' startIcon={<SaveIcon />}>
							<Typography>Save</Typography>
						</Button>
					</Grid>
				</form>
			</Menu>
		</Box>
	);
};

export default Options;
