import { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import ClientList from './ClientList';
import { useAuth0 } from '@auth0/auth0-react';
import axios from '../axios/axios2';
import Loading from '../misc/Loading';

const Management = (): JSX.Element => {
	const { user } = useAuth0();
	const [role, setRole] = useState(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		axios
			.get('/users/' + user?.email)
			.then((res) => res.data)
			.then((role) => setRole(role[0].app_metadata.permission))
			.then(() => setIsLoading(false));
	}, [role, isLoading, user?.email]);

	if (isLoading) {
		return <Loading />;
	} else {
		if (role === 'admin') {
			return (
				<Container>
					<ClientList />
				</Container>
			);
		} else {
			return <div>Access Denied</div>;
		}
	}
};

export default Management;
