import { useAppDispatch } from '../../store';
import { useAppSelector } from '../../store';
import { selectedClient, selectComputers } from '../slices/clientsSlice';
import Loading from '../misc/Loading';
import { Grid, Typography } from '@mui/material';
import RptTotals from './RptTotals';
import RptDevice from './RptDevice';

const Report = () => {
	const client = useAppSelector(selectedClient);
	const status = useAppSelector((state) => state.clients.status);

	if (status !== 'succeeded') {
		return <Loading />;
	} else {
		console.log(client);
		return (
			<div
				style={{
					border: '1px solid',
					borderColor: '#d8dbd1',
					borderRadius: '4px',
					backgroundColor: '#d8dbd1',
					marginTop: '30px',
					marginBottom: '30px',
					width: '55%',
					boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
					alignItems: 'center',
					justifyContent: 'center',
					marginLeft: 'auto',
					marginRight: 'auto',
				}}
			>
				<Grid container spacing={3} padding={3}>
					<Grid item xs={12}>
						<Typography variant='h4' textAlign='center'>
							CIS Compliance Level 1 Report for {client && client.clientName}
						</Typography>
					</Grid>
					<RptTotals computers={client?.computers!} />
					<RptDevice computers={client?.computers!} />
				</Grid>
			</div>
		);
	}
};

export default Report;
