import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { NavLink, useLocation } from 'react-router-dom';
import EmojiNatureIcon from '@mui/icons-material/EmojiNature';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useAppSelector, useAppDispatch } from '../../store';
import {
	fetchFilteredClients,
	selectAllClients,
	selectedClientId,
	setSelectedClient,
} from '../slices/clientsSlice';
import { fetchFilteredNotifications } from '../slices/notificationSlice';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Client } from '../../common/common';

const pages = ['Devices', 'Management', 'Deployment', 'Report', 'Admin'];
//const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function Navbar() {
	const { user, isLoading } = useAuth0();
	const dispatch = useAppDispatch();
	const clients = useAppSelector(selectAllClients);
	const status = useAppSelector((state) => state.clients.status);
	const selectedClient = useAppSelector(selectedClientId);

	useEffect(() => {
		if (status === 'idle') {
			dispatch(fetchFilteredClients(user?.email as string));
		}
		if (status === 'succeeded') {
			dispatch(
				fetchFilteredNotifications(clients.map((c: Client) => c._id as String))
			);
		}
	}, [status]);

	const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
	//* For profile icon
	//const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
	const { pathname } = useLocation();

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};

	//* For profile icon
	/*const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};*/

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	//* For profile icon
	/*const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};*/

	const handleChange = (event: SelectChangeEvent) => {
		dispatch(setSelectedClient(event.target.value));
	};
	if (status !== 'succeeded' || isLoading) {
		return <div></div>;
	}
	return (
		<AppBar position='static'>
			<Container maxWidth='xl'>
				<Toolbar disableGutters>
					<EmojiNatureIcon
						sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
					/>
					<Typography
						variant='h6'
						noWrap
						sx={{
							mr: 2,
							display: { xs: 'none', md: 'flex' },
							fontFamily: 'monospace',
							fontWeight: 700,
							letterSpacing: '.3rem',
							color: 'inherit',
							textDecoration: 'none',
						}}
					>
						<NavLink style={{ textDecoration: 'none', color: 'white' }} to='/'>
							SecureMyDesktop
						</NavLink>
					</Typography>
					<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
						<IconButton
							size='large'
							aria-label='account of current user'
							aria-controls='menu-appbar'
							aria-haspopup='true'
							onClick={handleOpenNavMenu}
							color='inherit'
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id='menu-appbar'
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: 'block', md: 'none' },
							}}
						>
							{pages.map((page) => (
								<MenuItem key={page} onClick={() => handleCloseNavMenu}>
									<Typography textAlign='center'>{page}</Typography>
								</MenuItem>
							))}
						</Menu>
					</Box>
					<EmojiNatureIcon
						sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}
					/>
					<Typography
						variant='h5'
						noWrap
						component='a'
						href=''
						sx={{
							mr: 2,
							display: { xs: 'flex', md: 'none' },
							flexGrow: 1,
							fontFamily: 'monospace',
							fontWeight: 700,
							letterSpacing: '.3rem',
							color: 'inherit',
							textDecoration: 'none',
						}}
					>
						SecureMyDesktop
					</Typography>
					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
						{pages.map((page) => (
							<Button
								key={page}
								component={NavLink}
								to={`/${page}`}
								onClick={handleCloseNavMenu}
								sx={{
									my: 2,
									color: 'white',
									display: 'block',
									fontWeight: pathname === '/' + page ? 'bolder' : 'normal',
								}}
							>
								{page}
							</Button>
						))}
					</Box>
					<Box
						sx={{
							flexGrow: 0,
							display: { xs: 'none', md: 'flex' },
							minWidth: '250px',
							maxWidth: '250px',
							'& .MuiInputBase-root': {
								borderBottom: '1px solid #2e7d32',
								backgroundColor: '#e5e9de',
							},
							'& .MuiFilledInput-root': {
								borderColor: '#2e7d32',
								backgroundColor: '#e5e9de',
							},
							'& .MuiFilledInput:focus': {
								borderColor: '#2e7d32',
								backgroundColor: '#e5e9de!important',
							},
							'& .MuiFocused': {
								borderColor: '#2e7d32',
								backgroundColor: '#e5e9de!important',
							},
							'& .Mui-focused': {
								backgroundColor: '#e5e9de!important',
							},
							'& .MuiFormLabel:focus': {
								backgroundColor: 'rbga(0,0,0,0.38)!important',
							},
							'& .MuiSelectRoot.Mui-focused': {
								backgroundColor: '#e5e9de!important',
							},
							'& label.Mui-focused': {
								backgroundColor: 'rgba(0, 0, 0, 0.0)!important',
							},
						}}
					>
						<FormControl variant='filled' fullWidth>
							<InputLabel id='clients-label'>Client</InputLabel>
							<Select
								labelId='clients-label'
								id='simple-select'
								label='Client'
								onChange={handleChange}
								value={selectedClient}
								sx={{
									'&:hover': {
										//you want this to be the same as the backgroundColor above
										backgroundColor: '#e5e9de',
									},
								}}
							>
								{clients.map((c: Client) => (
									<MenuItem key={c._id} value={c._id}>
										{c.clientName}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
}
export default Navbar;

/*
?This goes at the bottom of the toolbar when ready this is for the profile button
<Box sx={{ flexGrow: 0 }}>
						<Tooltip title='Open settings'>
							<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
								<Avatar alt='Remy Sharp' src='/static/images/avatar/2.jpg' />
							</IconButton>
						</Tooltip>
						<Menu
							sx={{ mt: '45px' }}
							id='menu-appbar'
							anchorEl={anchorElUser}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={Boolean(anchorElUser)}
							onClose={handleCloseUserMenu}
						>
							{settings.map((setting) => (
								<MenuItem
									key={setting}
									onClick={handleCloseUserMenu}
									component={NavLink}
									to={setting.toLowerCase()}
								>
									<Typography textAlign='center'>{setting}</Typography>
								</MenuItem>
							))}
						</Menu>
					</Box>
					*/
