import { Box, Grid } from '@mui/material';
import { useEffect } from 'react';
import Client from './Client';
import { useAppSelector, useAppDispatch } from '../../store';
import { selectAllClients, selectedClientId } from '../slices/clientsSlice';
import { fetchUsers } from '../slices/userSlice';
import Loading from '../misc/Loading';
import { Client as IClient } from '../../common/common';

const ClientList = () => {
	const dispatch = useAppDispatch();
	const clients = useAppSelector(selectAllClients);
	const status = useAppSelector((state) => state.clients.status);
	const status2 = useAppSelector((state) => state.users.status);
	const selClient = useAppSelector(selectedClientId);

	useEffect(() => {
		if (status === 'idle' || status2 === 'idle') {
			dispatch(fetchUsers());
		}
	}, [status, status2]);

	if (status !== 'succeeded' || status2 !== 'succeeded') {
		return <Loading />;
	} else {
		return (
			<Grid
				container
				spacing={2}
				sx={{
					border: '1px solid',
					borderColor: '#e5e9de',
					borderRadius: '4px',
					backgroundColor: '#e5e9de',
					mt: 4,
					boxShadow: 2,
					height: 720,
					paddingX: 1,
				}}
			>
				<Grid item lg={12}>
					<Box sx={{ height: 600, width: '100%' }}>
						<Client
							client={
								clients.find((c: IClient) => c._id === selClient) as IClient
							}
						/>
					</Box>
				</Grid>
			</Grid>
		);
	}
};

export default ClientList;
