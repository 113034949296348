import { ThemeOptions, createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
    offWhite: Palette["primary"];
  }

  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
    offWhite?: PaletteOptions["primary"];
    type?: string;
  }

  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    overrides?: {};
    status?: {
      danger?: string;
    };
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

declare module "@mui/material/Checkbox" {
  interface CheckboxPropsColorOverrides {
    neutral: true;
  }
}

declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides {
    neutral: true;
    offWhite: true;
  }
}

export const Theme: ThemeOptions = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    type: "light",
    primary: {
      main: "#23521e",
    },
    secondary: {
      main: "#ffffff",
    },
    text: {
      primary: "#030401",
      secondary: "rgba(0, 0, 0, 0.38)",
    },
    background: {
      default: "#f3f8ec",
      paper: "#ffffff",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
    offWhite: {
      main: "#ced2c8",
      contrastText: "#fff",
    },
    //		divider: 'rgba(0,0,0,0.52)',
  },
});
