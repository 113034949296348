import React, { useEffect } from 'react';
import {
	List,
	ListItem,
	Checkbox,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
	Box,
	Stack,
} from '@mui/material';
import { useState } from 'react';
import { Optionals } from '../../common/common';
import TuneIcon from '@mui/icons-material/Tune';

const OptionSelect = (props: {
	optionals: Optionals;
	onChange: ({
		name,
		value,
		error,
	}: {
		name: string;
		value: string | Optionals;
		error: string | boolean;
	}) => void;
}) => {
	const [optionals, setOptionals] = useState<Optionals>(props.optionals);

	useEffect(() => {
		setOptionals(props.optionals);
	}, [props.optionals]);

	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newOptions = Object.assign({}, optionals);

		newOptions[e.target.name] = newOptions[e.target.name] === 1 ? 0 : 1;

		setOptionals(newOptions);
		props.onChange({
			name: 'optionals',
			value: newOptions,
			error: false,
		});
	};

	return (
		<div>
			<Box
				sx={{
					overflow: 'hidden',
					border: 1,
					borderRadius: '4px',
					width: '100%',
					background: '#d8dbd1',
					borderColor: '#d8dbd1',
					boxShadow: 2,
				}}
			>
				<Stack direction='row' alignItems='center' gap={1} m={1}>
					<TuneIcon fontSize='large' />
					<Typography variant='h5'>Client Default Options</Typography>
				</Stack>
				<List
					sx={{
						minHeight: 450,
						maxHeight: 450,
						overflow: 'auto',
						borderTop: 1,
						borderColor: '#CDD1C6',
					}}
				>
					{Object.keys(optionals).map((key, index) => {
						return (
							<ListItem key={key} disablePadding>
								<ListItemButton>
									<ListItemIcon>
										<Checkbox
											edge='start'
											checked={optionals[key] === 1}
											onChange={onChangeHandler}
											tabIndex={-1}
											inputProps={{ name: key }}
										/>
									</ListItemIcon>
									<ListItemText id={key} primary={key.replaceAll('_', ' ')} />
								</ListItemButton>
							</ListItem>
						);
					})}
				</List>
			</Box>
		</div>
	);
};

export default OptionSelect;
