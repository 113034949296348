import { Button, Grid } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useAppDispatch, useAppSelector } from '../../store';
import {
	refetchFilteredClients,
	selectAllClients,
} from '../slices/clientsSlice';
import { fetchFilteredNotifications } from '../slices/notificationSlice';
import { Client } from '../../common/common';

type Props = {
	user: string;
};

const RefreshButton = ({ user }: Props) => {
	const dispatch = useAppDispatch();
	const clients = useAppSelector(selectAllClients);

	const clickHandler = () => {
		dispatch(refetchFilteredClients(user));
		dispatch(
			fetchFilteredNotifications(clients.map((c: Client) => c._id as String))
		);
	};

	return (
		<Grid item xs={3} textAlign='center'>
			<Button startIcon={<RefreshIcon />} onClick={clickHandler}>
				Refresh
			</Button>
		</Grid>
	);
};

export default RefreshButton;
