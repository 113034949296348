import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Typography, Container } from '@mui/material';

export default function Logout() {
	const { logout } = useAuth0();

	useEffect(() => {
		logout({
			logoutParams: { returnTo: 'https://my.securemydesktop.com' },
		});
	});

	return (
		<Container maxWidth='md'>
			<Typography variant='h4' align='center' mt={2}>
				You have successfully logged out.
			</Typography>
			<Typography variant='h6' align='center' mt={2}>
				You will be redirected to the login page momentarily.
			</Typography>
		</Container>
	);
}
