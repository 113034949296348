import { GridToolbarContainer } from '@mui/x-data-grid';
import { Grid, Typography, Stack } from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AddUser from './AddUser';

type Props = {
	onChangeHandler: (newUser: string) => void;
};

const UserToolBar = ({ onChangeHandler }: Props) => {
	return (
		<GridToolbarContainer>
			<Grid container direction='row'>
				<Grid item xs={9}>
					<Stack direction='row' alignItems='center' gap={1} m={1}>
						<AdminPanelSettingsIcon fontSize='large' />
						<Typography variant='h5'>Users</Typography>
					</Stack>
				</Grid>
				<Grid item xs={3}>
					<Stack
						direction='row'
						alignItems='center'
						justifyContent='flex-end'
						gap={1}
						m={1}
					>
						<AddUser onChangeHandler={onChangeHandler} />
					</Stack>
				</Grid>
			</Grid>
		</GridToolbarContainer>
	);
};

export default UserToolBar;
