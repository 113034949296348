import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from '../axios/axios2';
import { RootState } from '../../store';

interface UserState {
	users: [];
	status: string;
	error?: string;
	refetch: boolean;
}

const initialState: UserState = {
	users: [],
	status: 'idle',
	error: '',
	refetch: false,
};

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
	const res = await axios.get('/users');
	return res.data;
});

export const refetchUsers = createAsyncThunk('users/refetchUsers', async () => {
	const res = await axios.get('/users');
	return res.data;
});

export const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		update: (state, action: PayloadAction<any>) => {
			const newArray: string[] = [...state.users];
			newArray[action.payload.index] = action.payload.client;
			return {
				...state,
				clients: newArray,
			};
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchUsers.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetchUsers.fulfilled, (state, action) => {
				state.status = 'succeeded';
				// Add any fetched posts to the array
				state.users = action.payload;
			})
			.addCase(fetchUsers.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			})
			.addCase(refetchUsers.pending, (state, action) => {
				state.refetch = true;
			})
			.addCase(refetchUsers.fulfilled, (state, action) => {
				//state.status = 'succeeded';
				// Add any fetched posts to the array
				state.users = action.payload;
				state.refetch = false;
			})
			.addCase(refetchUsers.rejected, (state, action) => {
				//state.status = 'failed';
				state.error = action.error.message;
			});
	},
});

export const { update } = usersSlice.actions;

export default usersSlice.reducer;

export const selectAllUsers = (state: RootState) => state.users.users;
