import axios from 'axios';

const instance = axios.create({
	//baseURL: 'http://localhost:5000',
	baseURL: 'https://api.securemydesktop.com/',
	headers: {
		authorization:
			'ACfwQ5Iqup6xrOF!gZ9MMb1aPfTERLLPBerou5qiTf1BHCfY!bMOOFy9WjTsv7j8mW31Bt0v3zrwHiH3F7r3WTlpc6pF9UXyAL?',
	},
});

export default instance;
