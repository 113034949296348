import React from 'react';
import { TextField, FormControl, InputLabel, Input } from '@mui/material';
import { Optionals } from '../../common/common';

type FieldProps = {
	placeholder?: string;
	name: string;
	value: string;
	validate: (val: string) => string | boolean;
	onChange: ({
		name,
		value,
		error,
	}: {
		name: string;
		value: string;
		error: string | boolean;
	}) => void;
	label: string;
};

type FieldState = {
	value: string;
	error: string | boolean;
};

class DftField extends React.Component<FieldProps, FieldState> {
	state: FieldState = {
		value: this.props.value,
		error: false,
	};

	static getDerivedStateFromProps(nextProps: FieldProps) {
		return { value: nextProps.value };
	}

	onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const name = this.props.name;
		const value = evt.target.value;
		const error = this.props.validate(value)
			? this.props.validate(value)
			: false;

		this.setState({ value, error });

		this.props.onChange({ name, value, error });
	};

	render() {
		if (this.state.error) {
			return (
				<TextField
					required
					sx={{ mt: 3 }}
					fullWidth
					error
					placeholder={this.props.placeholder}
					value={this.state.value}
					onChange={this.onChange}
					className='form-control'
					label={this.props.label}
					helperText={this.state.error}
					variant='standard'
				/>
			);
		} else {
			return (
				<TextField
					required
					sx={{ mt: 3 }}
					fullWidth
					placeholder={this.props.placeholder}
					value={this.state.value}
					onChange={this.onChange}
					className='form-control'
					label={this.props.label}
					variant='standard'
				/>
			);
		}
	}
}

export default DftField;
