import { GridToolbarContainer } from '@mui/x-data-grid';
import { Grid, TextField } from '@mui/material';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RadarIcon from '@mui/icons-material/Radar';
import UpdateIcon from '@mui/icons-material/Update';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from './ConfirmationModal';
import RefreshButton from './RefreshButton';
import { useState } from 'react';

import DeleteConfirmation from './DeleteConfirmation';

type Props = {
	actionHandler: () => void;
	forceDelDevice: () => void;
	onSearch: (value: string) => void;
	user: string;
};

const GridToolBar = ({
	actionHandler,
	onSearch,
	user,
	forceDelDevice,
}: Props) => {
	const [search, setSearch] = useState('');

	const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value);
		onSearch(e.target.value);
	};

	return (
		<GridToolbarContainer>
			<Grid container alignItems='center' spacing={2} m={1}>
				<Grid item xs={3}>
					<TextField
						label='Search'
						fullWidth
						variant='filled'
						onChange={handleSearch}
						value={search}
					/>
				</Grid>
				<ConfirmationModal
					label='Scan'
					Icon={WifiTetheringIcon}
					TopIcon={RadarIcon}
					onClickHandler={actionHandler}
					color='primary'
					name='scan'
				/>
				<ConfirmationModal
					label='Update'
					Icon={UpgradeIcon}
					TopIcon={UpdateIcon}
					onClickHandler={actionHandler}
					color='success'
					name='update'
				/>
				<DeleteConfirmation
					label='Delete'
					Icon={DeleteIcon}
					TopIcon={HighlightOffIcon}
					onClickHandler={actionHandler}
					forceDelDevice={forceDelDevice}
					color='error'
					name='delete'
				/>
				<RefreshButton user={user} />
			</Grid>
		</GridToolbarContainer>
	);
};

export default GridToolBar;
