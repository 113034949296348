import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios2 from '../axios/axios2';
import axios from '../axios/axios';
import { Computer, ComputerInput, Notification } from '../../common/common';
import { RootState } from '../../store';

interface ComputerState {
	computers: Computer[];
	status: string;
	error: string | undefined;
}

const initialState: ComputerState = {
	computers: [],
	status: 'idle',
	error: '',
};

export const fetchFilteredComputers = createAsyncThunk(
	'computers/fetchFilteredComputers',
	async (email: string) => {
		const res = await axios2.get('/computers/filter/' + email);
		return res.data;
	}
);

export const deleteComputers = createAsyncThunk(
	'computers/deleteComputer',
	async ({
		computers,
		clientId,
	}: {
		computers: Computer[];
		clientId: string;
	}) => {
		for (const computer of computers) {
			await axios.delete('/computers/' + computer._id + '/' + clientId);
			const notifications = await axios.get(
				'/notifications/' + computer.hostName + '/' + clientId
			);
			notifications.data.map(async (n: Notification) => {
				await axios.delete('/notifications/' + n._id);
			});
		}
		return 'successful';
	}
);

export const updateComputer = createAsyncThunk(
	'computers/updateComputer',
	async ({ computer, _id }: { computer: ComputerInput; _id: string }) => {
		const url = '/computers/' + _id;
		const payload = Object.assign({}, computer);
		const res = await axios.put(url, payload);
		return res.data;
	}
);

export const computerSlice = createSlice({
	name: 'computers',
	initialState,
	reducers: {
		update: (state, action) => {
			const newArray = [...state.computers];
			newArray[action.payload.index] = action.payload.computer;
			return {
				...state,
				computers: newArray,
			};
		},
	},
	extraReducers(builder) {
		builder
			.addCase(fetchFilteredComputers.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(fetchFilteredComputers.fulfilled, (state, action) => {
				state.status = 'succeeded';
				// Add any fetched posts to the array
				state.computers = action.payload;
			})
			.addCase(fetchFilteredComputers.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			})
			.addCase(deleteComputers.fulfilled, (state, action) => {
				state.status = 'succeeded';
				// Add any fetched posts to the array
			})
			.addCase(deleteComputers.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			})
			.addCase(updateComputer.fulfilled, (state, action) => {
				const computers = [...state.computers];
				const index = state.computers.findIndex(
					(computer) => computer._id === action.payload._id
				);
				computers[index] = action.payload;
				state.computers = computers;
			});
	},
});

export const { update } = computerSlice.actions;

export default computerSlice.reducer;

export const selectComputers = (state: RootState) => state.computers.computers;
