import {
	fetchClients,
	selectAllClients,
	selectedClientId,
} from '../slices/clientsSlice';
import { useState, useEffect } from 'react';
import { Typography, Box, Grid } from '@mui/material';
import ClientDeployment from './ClientDeployment';
import { useAppSelector, useAppDispatch } from '../../store';
import Loading from '../misc/Loading';

const Deployment = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const clients = useAppSelector(selectAllClients);
	const status = useAppSelector((state) => state.clients.status);
	const selectedClient = useAppSelector(selectedClientId);
	const [clientId, setClientId] = useState<string>();

	useEffect(() => {
		if (status === 'idle') {
			dispatch(fetchClients());
		}
		if (status === 'succeeded') {
			setClientId(clients[0]._id as string);
		}
	}, [status]);

	if (status !== 'succeeded' || typeof clientId === 'undefined') {
		return <Loading />;
	} else {
		return (
			<Grid container spacing={2} alignItems='center' justifyContent='center'>
				<Grid item xs={8}>
					<Box sx={{ height: 600, width: '100%' }}>
						<Typography variant='h4' align='center' mt={2} mb={2}>
							Deployments
						</Typography>
						<ClientDeployment clientId={selectedClient} />
					</Box>
				</Grid>
			</Grid>
		);
	}
};

export default Deployment;
