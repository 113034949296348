import {
	MenuItem,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	FormControl,
	InputLabel,
	Stack,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useEffect, useState } from 'react';
import { AuthUser } from '../../common/common';
import DftField from '../misc/DftField';
import axios from '../axios/axios2';
import { useAppDispatch } from '../../store';
import { refetchUsers } from '../slices/userSlice';

type Props = {
	open: boolean;
	handleClose: () => void;
	user: AuthUser;
	deleteUser: () => void;
};

interface FieldErrors {
	[key: string]: string | undefined | boolean;
}

const UserCard = ({ open, handleClose, user, deleteUser }: Props) => {
	const dispatch = useAppDispatch();
	const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});
	const [form, setForm] = useState({
		email: user.email,
		name: user.name,
		role: user.app_metadata.permission,
	});

	useEffect(() => {
		setForm({
			email: user.email,
			name: user.name,
			role: user.app_metadata.permission,
		});
	}, [user]);

	const handleRole = (event: SelectChangeEvent) => {
		setForm({
			...form,
			role: event.target.value,
		});
	};

	const updateForm = ({
		name,
		value,
		error,
	}: {
		name: string;
		value: string;
		error: string | boolean;
	}) => {
		const fields = { ...form, [name]: value };
		const errors: FieldErrors = { ...fieldErrors, [name]: error };

		setFieldErrors(errors);
		setForm(fields);
	};

	const handleSubmit = () => {
		axios.put('/users/' + user.user_id, form).then((res) => {
			dispatch(refetchUsers());
			handleClose();
		});
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Manage User</DialogTitle>
			<DialogContent>
				<DftField
					name='email'
					value={form.email as string}
					onChange={updateForm}
					validate={(val) => (val ? false : 'Email Required')}
					label='Email'
				/>
				<DftField
					name='name'
					value={form.name as string}
					onChange={updateForm}
					validate={(val) => (val ? false : 'Name Required')}
					label='name'
				/>
				<FormControl variant='standard' fullWidth sx={{ mt: 3 }}>
					<InputLabel id='role-label'>Role</InputLabel>
					<Select
						labelId='role-label'
						id='role'
						value={form.role}
						onChange={handleRole}
						label='Age'
					>
						<MenuItem selected value={'user'}>
							User
						</MenuItem>
						<MenuItem value={'admin'}>Admin</MenuItem>
					</Select>
				</FormControl>
				<Stack
					direction='row'
					mt={5}
					spacing={2}
					alignItems='center'
					justifyContent='center'
				>
					<Button variant='contained' color='error' onClick={deleteUser}>
						Delete User
					</Button>
					<Button disabled variant='contained'>
						Reset Password
					</Button>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleSubmit}>Save</Button>
			</DialogActions>
		</Dialog>
	);
};

export default UserCard;
