import axios from 'axios';

const instance = axios.create({
	//baseURL: 'http://localhost:5000',
	baseURL: 'https://api.securemydesktop.com/',
	headers: {
		authorization:
			'63ee85c7df49ce2521af832d!I81xHgNwvqYfIk62YMmDbgruf49ce2521af832d!I81xHgNwvq',
	},
});

export default instance;
