import React, { useEffect } from 'react';
import { Container, Typography, Button, Grid, Divider } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import Field from '../misc/Field';
import { useState } from 'react';
import { useAppDispatch } from '../../store';
import { updateClient } from '../slices/clientsSlice';
import UserSelect from '../users/UserSelect';
import OptionSelect from './OptionSelect';
import isEmail from 'validator/lib/isEmail';
import {
	Client as IClient,
	Optionals,
	ClientInput,
	Computer,
} from '../../common/common';

interface FieldErrors {
	[key: string]: string | undefined | boolean;
}

const Client = (props: { client: IClient }) => {
	const [client, setClient] = useState<ClientInput>({
		...props.client,
		computers: props.client.computers.map((c: Computer) => c._id),
	} as ClientInput);
	const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});
	const dispatch = useAppDispatch();

	useEffect(() => {
		setClient({
			...props.client,
			computers: props.client.computers.map((c: Computer) => c._id),
		} as ClientInput);
	}, [props.client]);

	const onSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		if (validate()) return;
		console.log(client);
		dispatch(updateClient(client));
	};

	const updateForm = ({
		name,
		value,
		error,
	}: {
		name: string;
		value: string | Optionals | string[];
		error: string | boolean;
	}) => {
		const fields: ClientInput = { ...client, [name]: value };
		const errors: FieldErrors = { ...fieldErrors, [name]: error };

		setFieldErrors(errors);
		setClient(fields);
	};

	const validate = (): boolean => {
		const errMessages = Object.keys(fieldErrors).filter((k) => fieldErrors[k]);

		if (!client.firstName) return true;
		if (!client.lastName) return true;
		if (!client.clientName) return true;
		if (!client.licenseCount) return true;
		if (!client.email) return true;
		if (!client.abbreviation) return true;
		if (errMessages.length) return true;
		return false;
	};

	return (
		<Container>
			<form onSubmit={onSubmit}>
				<Grid container mt={1} mb={2}>
					<Grid item xs={1}></Grid>
					<Grid item xs={10}>
						<Typography variant='h4' align='center'>
							{props.client.clientName}
						</Typography>
					</Grid>

					<Grid
						item
						xs={1}
						display='flex'
						justifyContent='flex-end'
						alignItems='flex-end'
					>
						<Button type='submit' variant='contained' endIcon={<SaveIcon />}>
							Save
						</Button>
					</Grid>
				</Grid>
				<Divider
					sx={{
						mb: 2,
					}}
				/>

				<Grid container spacing={2}>
					<Grid item xs={2}>
						<Field
							name='clientName'
							value={client.clientName as string}
							onChange={updateForm}
							validate={(val) => (val ? false : 'Client Name Required')}
							label='Client Name'
						/>
					</Grid>
					<Grid item xs={2}>
						<Field
							name='abbreviation'
							value={client.abbreviation as string}
							onChange={updateForm}
							validate={(val) => (val ? false : 'Abbreviation Required')}
							label='Abbreviation'
						/>
					</Grid>
					<Grid item xs={2}>
						<Field
							name='licenseCount'
							value={client.licenseCount as number}
							onChange={updateForm}
							validate={(val) => (val ? false : 'License Count Required')}
							label='Licence Seats'
						/>
					</Grid>
					<Grid item xs={2}>
						<Field
							name='email'
							value={client.email as string}
							onChange={updateForm}
							validate={(val) =>
								isEmail(val as string) ? false : 'Invalid Email'
							}
							label='Contact Email'
						/>
					</Grid>
					<Grid item xs={2}>
						<Field
							name='firstName'
							value={client.firstName as string}
							onChange={updateForm}
							validate={(val) => (val ? false : 'First Name Required')}
							label='First Name'
						/>
					</Grid>
					<Grid item xs={2}>
						<Field
							name='lastName'
							value={client.lastName as string}
							onChange={updateForm}
							validate={(val) => (val ? false : 'Last Name Required')}
							label='Last Name'
						/>
					</Grid>
				</Grid>
				<Divider sx={{ mt: 2, mb: 2 }} />
				<Grid container spacing={3} mb={2}>
					<Grid item xs={8}>
						<UserSelect
							users={client.users as string[]}
							onChange={updateForm}
						/>
					</Grid>
					<Grid item xs={4}>
						<OptionSelect
							optionals={client.optionals as Optionals}
							onChange={updateForm}
						/>
					</Grid>
				</Grid>
			</form>
		</Container>
	);
};

export default Client;
