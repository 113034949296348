import { useState, useCallback } from 'react';
import axios from '../axios/axios2';
import { useAppSelector, useAppDispatch } from '../../store';
import {
	refetchFilteredClients,
	selectAllClients,
	selectComputers,
	updateFriendlyName,
} from '../slices/clientsSlice';
import { updateComputer, deleteComputers } from '../slices/computerSlice';
import { Optionals, Client, Computer, Notification } from '../../common/common';
import LinearProgress from '@mui/material/LinearProgress';
import {
	DataGrid,
	GridCellEditStopParams,
	GridCellEditStopReasons,
	GridColDef,
	GridRenderCellParams,
	GridRowSelectionModel,
	MuiEvent,
	GridRowModel,
} from '@mui/x-data-grid';
import { Checkbox, Box, Snackbar, Alert } from '@mui/material';
import GridToolBar from './GridToolBar';
import Options from './Options';
import Loading from '../misc/Loading';
import ReportModal from './ReportModal';
import {
	fetchFilteredNotifications,
	selectAllNotifications,
} from '../slices/notificationSlice';

interface Action {
	clientId: string;
	hostName: string;
	action: string;
}

interface Payload {
	computers: Computer[];
	clientId: string;
}

declare module '@mui/x-data-grid' {
	interface ToolbarPropsOverrides {
		actionHandler: (e: React.MouseEvent<HTMLElement>) => void;
		forceDelDevice: () => void;
	}
}

const DeviceList = (props: { user: string }) => {
	const columns: GridColDef[] = [
		{
			field: 'hostName',
			headerName: 'Host',
			flex: 1,
			editable: false,
		},
		{
			field: 'friendlyName',
			headerName: 'Friendly Name',
			flex: 1,
			editable: true,
		},
		{
			field: 'complianceScore',
			headerName: 'Compliance Score',
			flex: 1,
			editable: false,
			renderCell: (params: GridRenderCellParams) => {
				if (params.row.complianceScore) {
					return <ReportModal params={params} pushAction={pushAction} />;
				} else {
					return null;
				}
			},
		},
		{
			field: 'lastOnline',
			headerName: 'Last Online',
			flex: 1,
			editable: false,
		},
		{
			field: 'lastUser',
			headerName: 'Last User',
			flex: 0.75,
			editable: false,
		},
		{
			field: 'action',
			headerName: 'Queue',
			flex: 0.5,
			editable: false,
			valueGetter: (params) => {
				const notification: Notification[] = notifications.filter(
					(n: Notification) => n.hostName === params.row.hostName
				);
				if (notification.length === 0) {
					return 'None';
				}
				if (notification.find((n: Notification) => n.action === 'upgrade')) {
					return 'Upgrade';
				}
				if (notification.find((n: Notification) => n.action === 'delete')) {
					return 'Delete';
				}
				if (notification.find((n: Notification) => n.action === 'update')) {
					return 'Update';
				}
				if (notification.find((n: Notification) => n.action === 'scan')) {
					return 'Scan';
				}
			},
		},
		{
			field: 'optionals',
			headerName: 'Options',
			flex: 1,
			renderCell: (params: GridRenderCellParams) => {
				return (
					<Options
						computerId={params.row._id}
						optionals={params.row.optionals}
						updateOptionals={optionalsSubmit}
					/>
				);
			},
		},
	];

	const dispatch = useAppDispatch();
	const clients = useAppSelector(selectAllClients);
	const devices = useAppSelector(selectComputers);
	const status = useAppSelector((state) => state.clients.status);
	const notifications = useAppSelector(selectAllNotifications);
	const refetch = useAppSelector((state) => state.clients.refetch);
	const noti_refetch = useAppSelector((state) => state.notifications.refetch);
	const [search, setSearch] = useState('');
	const [snackOpen, setSnackOpen] = useState(false);
	const [selectedDevices, setSelectedDevices] = useState<GridRowSelectionModel>(
		[]
	);

	const handleSnackClose = (
		event?: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackOpen(false);
	};

	const handleChange = (value: string) => {
		setSearch(value);
	};

	const optionalsSubmit = (optionals: Optionals, computerId: string) => {
		const newOptions = {
			computer: { optionals: optionals },
			_id: computerId,
		};
		dispatch(updateComputer(newOptions));
	};

	const onFriendlyEditStop = (
		params: GridCellEditStopParams,
		event: MuiEvent
	) => {
		if (params.reason === GridCellEditStopReasons.cellFocusOut) {
			event.defaultMuiPrevented = true;
		}
	};

	const processRowUpdate = useCallback(
		async (newRow: GridRowModel) => {
			dispatch(updateFriendlyName(newRow));
			dispatch(
				updateComputer({
					computer: { friendlyName: newRow.friendlyName },
					_id: newRow._id,
				})
			);
			return newRow;
		},
		[dispatch]
	);

	const handleProcessRowUpdateError = useCallback((error: Error) => {
		console.log('Error');
	}, []);

	const actionSubmit = (e: React.MouseEvent<HTMLElement>) => {
		devices.forEach((d: Computer) => {
			if (selectedDevices.includes(d._id as string)) {
				const client = clients.find((c) => {
					return c.computers.map((computer) => computer._id).includes(d._id);
				});
				pushAction({
					clientId: (client as Client)._id as string,
					hostName: d.hostName,
					action: (e.target as HTMLTextAreaElement).name,
				});
			}
		});
		setSnackOpen(true);
	};

	const pushAction = async (payload: Action) => {
		await axios.post('/notifications', payload);
		dispatch(refetchFilteredClients(props.user));
		dispatch(
			fetchFilteredNotifications(clients.map((c: Client) => c._id as String))
		);
	};

	const forceDelDevice = () => {
		let payload: Payload = {
			computers: [],
			clientId: '',
		};
		devices.forEach((d: Computer) => {
			if (selectedDevices.includes(d._id as string)) {
				const client = clients.find((c) => {
					return c.computers.map((computer) => computer._id).includes(d._id);
				});
				payload.clientId = client?._id as string;
				payload.computers.push(d);
			}
		});
		dispatch(deleteComputers(payload));
		setSnackOpen(true);
	};

	if (status !== 'succeeded') {
		return <Loading />;
	} else {
		return (
			<div>
				<Box sx={{ height: 683, width: '90%', margin: 'auto' }}>
					<Snackbar
						open={snackOpen}
						autoHideDuration={6000}
						onClose={handleSnackClose}
					>
						<Alert
							onClose={handleSnackClose}
							severity='success'
							sx={{ width: '100%', backgroundColor: '#d8dbd1' }}
						>
							Action successfully dispatched!
						</Alert>
					</Snackbar>
					<DataGrid
						slots={{
							toolbar: GridToolBar,
							baseCheckbox: Checkbox,
							loadingOverlay: LinearProgress,
						}}
						slotProps={{
							toolbar: {
								actionHandler: actionSubmit,
								onSearch: handleChange,
								user: props.user,
								forceDelDevice: forceDelDevice,
							},
						}}
						loading={refetch || noti_refetch}
						getRowId={(row) => row._id}
						rows={devices.filter((computer: Computer) => {
							try {
								if (
									computer.hostName
										.toLocaleLowerCase()
										.includes(search.toLowerCase())
								) {
									return true;
								}
								if (
									computer.friendlyName
										.toLocaleLowerCase()
										.includes(search.toLowerCase())
								) {
									return true;
								}
								if (
									computer.lastUser
										.toLocaleLowerCase()
										.includes(search.toLowerCase())
								) {
									return true;
								}
							} catch (error) {
								console.log(error);
								return false;
							}
							return false;
						})}
						columns={columns}
						initialState={{
							pagination: {
								paginationModel: {
									pageSize: 9,
								},
							},
						}}
						pageSizeOptions={[9]}
						onCellEditStop={onFriendlyEditStop}
						checkboxSelection
						onProcessRowUpdateError={handleProcessRowUpdateError}
						onRowSelectionModelChange={(newSelection) => {
							setSelectedDevices(newSelection);
						}}
						processRowUpdate={processRowUpdate}
						sx={{
							background: '#e5e9de',
							borderColor: '#e5e9de',
							'&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
								outline: 'none !important',
							},
							boxShadow: 2,
						}}
					/>
				</Box>
			</div>
		);
	}
};

export default DeviceList;
