import { Box, Modal, Typography, Grid, Button } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useEffect, useState } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import CircularProgress, {
	CircularProgressProps,
} from '@mui/material/CircularProgress';

interface Action {
	clientId: string;
	hostName: string;
	action: string;
}

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 450,
	bgcolor: '#f3f8ec',
	border: '1px solid',
	borderRadius: '4px',
	borderColor: 'grey.300',
	boxShadow: 24,
	p: 4,
};

type Props = {
	params: GridRenderCellParams;
	pushAction: (payload: Action) => void;
};

function CircularProgressWithLabel(
	props: CircularProgressProps & { value: number }
) {
	return (
		<Box sx={{ position: 'relative', display: 'inline-flex' }}>
			<CircularProgress
				variant='determinate'
				value={100}
				size='9rem'
				color='offWhite'
				sx={{
					position: 'absolute',
					boxShadow: 2,
					borderRadius: '75px',
				}}
			/>
			<CircularProgress variant='determinate' size='9rem' {...props} />

			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					textAlign: 'center',
				}}
			>
				<Typography variant='h4' component='div' color={props.color}>
					{`${Math.round(props.value)}%`}
				</Typography>
			</Box>
		</Box>
	);
}

const ReportModal = ({ params, pushAction }: Props) => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [score, setScore] = useState<number>();

	useEffect(() => {
		const scores = params.row.complianceScore.split('/');
		setScore(Math.round((scores[0] / scores[1]) * 100));
	}, [score, params]);

	const handleUpdate = (e: React.MouseEvent<HTMLElement>) => {
		pushAction({
			clientId: params.row.clientId,
			hostName: params.row.hostName,
			action: 'update',
		});
		setOpen(false);
	};

	return (
		<Grid item xs={2}>
			<Button
				onClick={handleOpen}
				endIcon={<OpenInNewIcon />}
				sx={{
					width: '100%',
				}}
			>
				{params.row.complianceScore}
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style}>
					<Typography id='modal-modal-title' align='center' variant='h4'>
						Compliance score for <br /> {params.row.hostName}
					</Typography>

					<Grid
						container
						direction='column'
						alignItems='center'
						justifyContent='center'
						mb={2}
						mt={2}
					>
						<Grid item xs={3}>
							<CircularProgressWithLabel
								value={score as number}
								color={
									(score as number) >= 90
										? 'primary'
										: (score as number) >= 50
										? 'warning'
										: 'error'
								}
							/>
						</Grid>
					</Grid>
					<Box
						sx={{
							width: '100%',
							textAlign: 'center',
						}}
					>
						<Typography
							id='modal-modal-description'
							variant='h6'
							fontWeight='normal'
							sx={{ mt: 2 }}
						>
							<Typography variant='h6' component={'span'}>
								Scan Date:
							</Typography>{' '}
							{params.row.lastOnline}
						</Typography>
						<Typography
							id='modal-modal-description'
							align='center'
							variant='h6'
							fontWeight='normal'
							sx={{ mt: 2 }}
						>
							<Typography variant='h6' component={'span'}>
								System OS:
							</Typography>{' '}
							{params.row.operatingSystem}
						</Typography>
						<Typography
							id='modal-modal-description'
							align='center'
							variant='h6'
							fontWeight='normal'
							sx={{ mt: 2 }}
						>
							<Typography variant='h6' component={'span'}>
								Score:
							</Typography>{' '}
							{params.row.complianceScore}
						</Typography>
						<Typography
							id='modal-modal-description'
							align='center'
							variant='h6'
							fontWeight='normal'
							sx={{ mt: 2 }}
						>
							<Typography variant='h6' component={'span'}>
								Result:
							</Typography>{' '}
							This device {(score as number) >= 90 ? 'is' : 'is not'} CIS Level
							1 Compliant
						</Typography>
					</Box>
					<Grid
						container
						alignItems='center'
						justifyContent='center'
						spacing={2}
						mt={1}
					>
						<Grid item xs={4}>
							<Button
								variant='contained'
								color='neutral'
								onClick={handleClose}
								sx={{
									width: '100%',
								}}
							>
								Close
							</Button>
						</Grid>
						<Grid item xs={4}>
							<Button
								variant='contained'
								color='success'
								onClick={handleUpdate}
								sx={{
									width: '100%',
								}}
							>
								Update
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</Grid>
	);
};

export default ReportModal;
