import {
	MenuItem,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText,
	FormControl,
	InputLabel,
	Snackbar,
	Alert,
	Box,
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import axios from '../axios/axios2';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { connect } from 'react-redux';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import { AuthUser } from '../../common/common';
import { refetchUsers } from '../slices/userSlice';
import DftField from '../misc/DftField';
import PwdField from '../misc/PwdField';
import LinearProgress from '@mui/material/LinearProgress';

interface FieldErrors {
	[key: string]: string | undefined | boolean;
}

type Props = {
	onChangeHandler: (newUser: string) => void;
	allUsers: [];
};

const AddUser = ({ onChangeHandler, allUsers }: Props) => {
	const [snackOpen, setSnackOpen] = useState(false);
	const dispatch = useAppDispatch();
	const [open, setOpen] = useState(false);
	const [userEx, setUserEx] = useState(true);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [form, setForm] = useState({
		email: '',
		name: '',
		password: '',
		password_conf: '',
		role: 'user',
	});
	const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});
	const refetch = useAppSelector((state) => state.users.refetch);

	const updateForm = ({
		name,
		value,
		error,
	}: {
		name: string;
		value: string;
		error: string | boolean;
	}) => {
		const fields = { ...form, [name]: value };
		const errors: FieldErrors = { ...fieldErrors, [name]: error };

		setFieldErrors(errors);
		setForm(fields);
	};

	const handleSnackClose = (
		event?: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackOpen(false);
	};

	const handleInvite = () => {
		if (allUsers.map((u: AuthUser) => u.email).includes(form.email)) {
			onChangeHandler(form.email);
			handleClose();
			setSnackOpen(true);
		} else {
			if (userEx) {
				setUserEx(false);
			} else {
				if (validate()) return;
				axios.post('/users/', form).then((res) => {
					onChangeHandler(form.email);
					dispatch(refetchUsers());
					handleClose();
					setSnackOpen(true);
				});
			}
		}
	};

	const validate = () => {
		const errMessages = Object.keys(fieldErrors).filter((k) => fieldErrors[k]);

		if (!form.name) return true;
		if (!form.password) return true;
		if (!form.password_conf) return true;
		if (!form.email) return true;
		if (errMessages.length) return true;
		return false;
	};

	const handleRole = (event: SelectChangeEvent) => {
		setForm({
			...form,
			role: event.target.value,
		});
	};
	return (
		<div>
			<Button onClick={handleOpen} endIcon={<AddIcon />}>
				Add User
			</Button>
			<Dialog open={open} onClose={handleClose}>
				{refetch && (
					<Box sx={{ width: '100%' }}>
						<LinearProgress />
					</Box>
				)}
				<DialogTitle>Add User to Client</DialogTitle>
				<DialogContent>
					{!userEx && (
						<DialogContentText>
							The user you tried to add does not exist. Please fill out the
							following form to send them an email with an invite link to
							SecureMyDesktop.
						</DialogContentText>
					)}
					{userEx && (
						<DialogContentText>
							To give user access to this client, please enter their email
							address and send the invite. If they do not have an account yet,
							you will be prompted to fill out some fields to create their
							account.
						</DialogContentText>
					)}
					<DftField
						name='email'
						value={form.email as string}
						onChange={updateForm}
						validate={(val) => (val ? false : 'Email Required')}
						label='Email'
					/>
					{!userEx && (
						<Fragment>
							<DftField
								name='name'
								value={form.name as string}
								onChange={updateForm}
								validate={(val) => (val ? false : 'Name Required')}
								label='name'
							/>
							<FormControl variant='standard' fullWidth sx={{ mt: 3 }}>
								<InputLabel id='role-label'>Role</InputLabel>
								<Select
									labelId='role-label'
									id='role'
									value={form.role}
									onChange={handleRole}
									label='Age'
								>
									<MenuItem value={'user'}>User</MenuItem>
									<MenuItem value={'admin'}>Admin</MenuItem>
								</Select>
							</FormControl>
							<PwdField
								name='password'
								value={form.password as string}
								onChange={updateForm}
								validate={(val) =>
									val.length >= 10 &&
									/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(val) &&
									/\d/.test(val) &&
									/[A-Z]/.test(val)
										? false
										: 'Password does not meet complexity (10 character length, upper, lower, special, and number required)'
								}
								label='Confirm Password'
							/>
							<PwdField
								name='password_conf'
								value={form.password_conf as string}
								onChange={updateForm}
								validate={(val) =>
									val === form.password ? false : 'Passwords do not match'
								}
								label='Confirm Password'
							/>
						</Fragment>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button endIcon={<SendIcon />} onClick={handleInvite}>
						Add
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar
				open={snackOpen}
				autoHideDuration={6000}
				onClose={handleSnackClose}
			>
				<Alert
					onClose={handleSnackClose}
					severity='success'
					sx={{ width: '100%', backgroundColor: '#d8dbd1' }}
				>
					User successfully added to client!
				</Alert>
			</Snackbar>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { users } = state;
	return { allUsers: users.users };
}

export default connect(mapStateToProps)(AddUser);
