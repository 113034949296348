import React from 'react';
import {
	Box,
	Modal,
	Typography,
	Grid,
	Button,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Tooltip,
} from '@mui/material';
import { useState } from 'react';
import { SvgIcon } from '@mui/material';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '1px solid',
	borderRadius: '4px',
	borderColor: 'grey.300',
	boxShadow: 24,
	p: 4,
};

type Props = {
	label: string;
	Icon: React.FC;
	TopIcon: React.FC;
	onClickHandler: (e: React.MouseEvent<HTMLElement>) => void;
	color: 'primary' | 'success' | 'warning' | 'error';
	name: string;
	forceDelDevice: () => void;
};

const DeleteConfirmation = (props: Props) => {
	const [open, setOpen] = useState(false);
	const [checked, setChecked] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => {
		setOpen(false);
		setChecked(false);
	};

	const handleConfirm = (e: React.MouseEvent<HTMLElement>) => {
		setOpen(false);
		if (checked) {
			props.forceDelDevice();
		} else {
			props.onClickHandler(e);
		}
		setChecked(false);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	const Icon = props.Icon;
	const TopIcon = props.TopIcon;

	return (
		<Grid item xs={2}>
			<Button
				variant='contained'
				onClick={handleOpen}
				startIcon={<Icon />}
				color={props.color}
				sx={{
					width: '100%',
				}}
			>
				uninstall
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style}>
					<Grid
						container
						direction='column'
						alignItems='center'
						justifyContent='center'
						mb={2}
					>
						<Grid item xs={3}>
							<SvgIcon
								component={TopIcon}
								color={props.color}
								fontSize='large'
							/>
						</Grid>
					</Grid>
					<Typography id='modal-modal-title' align='center' variant='h5'>
						Do you really want to {props.label.toLowerCase()} these computers?
					</Typography>
					<Typography
						id='modal-modal-description'
						align='center'
						sx={{ mt: 2 }}
					>
						This process cannot be undone
					</Typography>
					<FormGroup
						sx={{
							display: 'flex',
							width: '100%',
							alignItems: 'center',
							mt: 1,
							mb: 2,
						}}
					>
						<Tooltip
							title='Use if the device no longer has the agent installed but it is still appearing on this board'
							placement='right'
							arrow
						>
							<FormControlLabel
								control={
									<Checkbox
										color='error'
										checked={checked}
										onChange={handleChange}
									/>
								}
								label='Remove Device'
							/>
						</Tooltip>
					</FormGroup>
					<Grid
						container
						alignItems='center'
						justifyContent='center'
						spacing={2}
					>
						<Grid item xs={4}>
							<Button
								variant='contained'
								color='neutral'
								onClick={handleClose}
								sx={{
									width: '100%',
								}}
							>
								Cancel
							</Button>
						</Grid>
						<Grid item xs={4}>
							<Button
								variant='contained'
								color={props.color}
								onClick={handleConfirm}
								sx={{
									width: '100%',
								}}
								name={props.name}
							>
								Uninstall
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</Grid>
	);
};

export default DeleteConfirmation;
