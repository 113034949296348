import { Button } from '@mui/material';
import { Client } from '../../common/common';

interface Props {
	clients: Client[];
}

function convertToCSV(data: string[][]) {
	const csv = data.map((row) => row.join(',')).join('\n');
	return `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
}

export default function PrintCSV({ clients }: Props) {
	//? Onclick function to generate and serve CSV
	const printCSV = () => {
		console.log(clients);
		const data = clients.map((c: Client) => {
			return [c.clientName, c.computers.length.toString()];
		});
		const csv = convertToCSV(data);
		const link = document.createElement('a');
		link.setAttribute('href', csv);
		link.setAttribute('download', 'companies_report.csv');
		link.click();
	};

	return (
		<Button variant='contained' onClick={printCSV}>
			Print Client Counts
		</Button>
	);
}
